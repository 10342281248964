import { render, staticRenderFns } from "./Pages.vue?vue&type=template&id=118cb7e6&"
import script from "./Pages.vue?vue&type=script&lang=js&"
export * from "./Pages.vue?vue&type=script&lang=js&"
import style0 from "./Pages.vue?vue&type=style&index=0&id=118cb7e6&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthPopup: require('/app/components/AuthPopup.vue').default})
