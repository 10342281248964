
import { mapState, mapActions } from 'vuex';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "~/plugins/firebase.js";

export default {
    data() {
        return {
        };
    },
    computed: {
        ...mapState(['userData'])
    },
    methods: {
        ...mapActions(['requestUserData']),
        openModal() {
            this.$refs.authPopup.openModal();
        }
    },
    mounted()
    {
        onAuthStateChanged(auth, user => this.requestUserData());
    }
}
