
import { auth } from "~/plugins/firebase.js";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { mapState, mapActions } from 'vuex';

export default {
    props: ['showByDefault'],
    data() {
        return {
            email: "",
            password: "",
            errorMessage: "",
            showModal: this.showByDefault
        };
    },
    computed: {
        ...mapState(['userData'])
    },
    methods: {
        ...mapActions(['requestUserData']),
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.errorMessage = "";
        },
        async loginWithEmailPassword() {
            try {
                await signInWithEmailAndPassword(auth, this.email, this.password);
                console.log("Logged in with email and password");
                this.requestUserData();
                this.closeModal();
            } catch (error) {
                console.error("Error logging in:", error.message);
                this.errorMessage = error.message;
            }
        },
        async loginWithGoogle() {
            const provider = new GoogleAuthProvider();
            try {
                await signInWithPopup(auth, provider);
                console.log("Logged in with Google");
                this.requestUserData();
                this.closeModal();
            } catch (error) {
                console.error("Error logging in with Google:", error.message);
                this.errorMessage = error.message;
            }
        }
    },
};
